import zh_CN from './zh_CN.json'
import es_419 from './es_419.json'
import fil from './fil.json'
import ja from './ja.json'
import fr from './fr.json'
import it_IT from './it_IT.json'
import ko from './ko.json'
import pt from './pt.json'
import ru_RU from './ru_RU.json'
import tr from './tr.json'
import en from './en.json'
import de from './de.json'
import es from './es.json'
import pl from './pl.json'
import pt_BR from './pt_BR.json'
import sv from './sv.json'
import ar from './ar.json'
import ar_EG from './ar_EG.json'
import id from './id.json'
import he_IL from './he_IL.json'
import ur from './ur_PK.json'
import th from './th.json'
import vi from './vi.json'
import ro from './ro.json'
import am from './am.json'
import ms_MY from './ms_MY.json'
import nl_NL from './nl_NL.json'

const resources = {
  ar: {
    translation: ar
  },
  ar_EG: {
    translation: ar_EG
  },
  cn: {
    translation: zh_CN
  },
  es_419: {
    translation: es_419
  },
  fil: {
    translation: fil
  },
  ja: {
    translation: ja
  },
  fr: {
    translation: fr
  },
  id: {
    translation: id
  },
  he_IL: {
    translation: he_IL
  },
  it: {
    translation: it_IT
  },
  pt: {
    translation: pt
  },
  kr: {
    translation: ko
  },
  ru: {
    translation: ru_RU
  },
  tr: {
    translation: tr
  },
  en: {
    translation: en
  },
  de: {
    translation: de
  },
  es: {
    translation: es
  },
  pt_BR: {
    translation: pt_BR
  },
  pl: {
    translation: pl
  },
  se: {
    translation: sv
  },
  th: {
    translation: th
  },
  ur: {
    translation: ur
  },
  vi: {
    translation: vi
  },
  ro: {
    translation: ro
  },
  am:{
    translation: am
  },
  ms_MY: {
    translation: ms_MY
  },
  nl_NL: {
    translation: nl_NL
  }
}

export { resources }
