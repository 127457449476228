import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './index'

i18n.use(initReactI18next).init({
  resources,
  // the language to use
  lng: 'en',
  // if the key is not found in lng, then the key is searched in fallback
  fallbackLng: 'en',
  debug: false,
  // have a common namespace used around the full app
  ns: ['translation'],
  defaultNS: 'translation',
  // allow an empty value to count as invalid (by default is true)
  returnEmptyString: false,
  // the separator to be used in t(category.key2)
  keySeparator: '.',

  interpolation: {
    escapeValue: false
  }
})

export default i18n
