import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentLanguage: 'en'
}

const language = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.currentLanguage = action.payload
    }
  }
})

export const { setLanguage } = language.actions
export default language.reducer
